import React, { ReactNode } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'
import styled from 'styled-components'
import { SpinnerElement } from 'components/elements/Spinner'
import Link from 'next/link'
import theme from 'stories/utils/theme'

interface Props {
    type?: 'primary' | 'secondary' | 'default' | 'white'
    borderRadius?: string
    rounded?: boolean
    loading?: boolean
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
    children?: ReactNode
    icon?: JSX.Element
    loadingArea?: string
    disabled?: boolean
    fullWidth?: boolean
    height?: number
    width?: number
    fixedBottom?: boolean
    color?: string
    disableFullWidth?: boolean
    buttonType?: 'button' | 'submit'
    dataId?: string
    className?: string
}

const Container = styled.div<{
    backgroundColor: string
    color: string
    backgroundHoverColor: string
    loading: boolean
    hasIcon: boolean
    borderColor: string | null
    borderRadius?: string
    fullWidth: boolean
    disabled: boolean
    height?: number
    width?: number
}>`
    background-color: ${(props) => props.backgroundColor};
    border-radius: ${(props) => props.borderRadius};
    color: ${(props) => props.color};
    cursor: ${(props) => (props.disabled ? 'normal' : 'pointer')};
    border: ${(props) => (props.borderColor ? '2px solid ' + props.borderColor : '2px solid ' + props.backgroundColor)};
    padding: ${(props) => (props.loading ? '4.5px' : '15px')};
    width: ${(props) => (props?.width ? `${props.width}px` : props.fullWidth ? '100%' : 'auto')};
    height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
    display: grid;
    place-content: center;
    place-items: center;
    grid-template-columns: ${(props) => (props.hasIcon ? 'auto auto' : 'auto')};
    grid-gap: ${(props) => (props.hasIcon ? '10px' : '0px')};

    &:hover {
        background: ${(props) => props.backgroundHoverColor};
    }

    & svg {
        max-width: 26px;
        fill: ${({ color }) => color};
    }
`

const NewButtonContainer = (props: Props) => {
    const { promiseInProgress } = usePromiseTracker({ area: props.loadingArea })
    const isLoading = (promiseInProgress && props.loadingArea !== undefined) || props.loading

    const type = props.type || 'primary'

    let backgroundColor = type === 'primary' ? 'var(--secondary-color)' : theme.colors.transparent
    let color = type === 'primary' ? 'white' : type === 'default' ? 'var(--text-light-gray)' : 'var(--secondary-color)'
    let backgroundHoverColor = type === 'primary' ? 'var(--secondary-color-dark)' : 'none'
    let borderColor = type === 'primary' ? null : type === 'default' ? 'var(--text-light-gray)' : 'var(--secondary-color)'
    const borderRadius = props.rounded ? theme.borderRadius.huge : props.borderRadius ?? theme.borderRadius.smallest

    if (type === 'white') {
        backgroundColor = theme.colors.white
        backgroundHoverColor = theme.colors.white
        borderColor = theme.colors.white
        color = 'transparent'
    }
    if (props.disabled) {
        backgroundColor = 'var(--gray)'
        backgroundHoverColor = 'var(--gray)'
        if (type === 'secondary') {
            borderColor = 'var(--gray)'
            color = 'var(--gray)'
            backgroundColor = 'white'
            backgroundHoverColor = 'white'
        }
    }
    if (props.color) {
        backgroundColor = props.type === 'secondary' ? theme.colors.white : props.color
        backgroundHoverColor = props.type === 'secondary' ? theme.colors.white : props.color
        borderColor = props.color
    }

    return (
        <Container
            backgroundColor={backgroundColor}
            color={color}
            backgroundHoverColor={backgroundHoverColor}
            loading={!!isLoading}
            hasIcon={!!props.icon}
            borderColor={borderColor}
            borderRadius={borderRadius}
            fullWidth={!!props.fullWidth}
            disabled={!!props.disabled}
            height={props.height}
            width={props.width}
            data-nosnippet="true"
        >
            {isLoading ? (
                <SpinnerElement />
            ) : (
                <>
                    {props.icon}
                    {props.children}
                </>
            )}
        </Container>
    )
}

interface ButtonElementProps {
    fixedBottom?: boolean
    disableFullWidth?: boolean
}
const ButtonElement = styled.button<React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonElementProps>`
    background: transparent;
    border: 0;
    padding: 0;
    width: ${({ disableFullWidth }) => (disableFullWidth ? 'auto' : '100%')};
    display: flex;
    justify-content: center;
    ${({ fixedBottom }) =>
        fixedBottom ? `position: fixed; bottom: 25px; left: 50%; transform: translateX(-50%); padding: 0 25px; ` : ''};
`

const NewButton = (props: Props) => (
    <ButtonElement
        type={props.buttonType ? props.buttonType : 'submit'}
        onClick={
            props.disabled
                ? () => {
                      /* do nothing */
                  }
                : props.onClick
        }
        disableFullWidth={props.disableFullWidth}
        fixedBottom={props.fixedBottom}
        disabled={props.disabled}
        data-id={props.dataId}
        className={props.className}
    >
        <NewButtonContainer {...props} />
    </ButtonElement>
)

const AElement = styled.a<{ fullWidth?: boolean }>`
    display: ${({ fullWidth }) => (fullWidth ? 'inline' : 'inline-block')};
`

export interface NewButtonAProps extends Props {
    href: string
    target?: '_blank'
}

export const NewButtonA = (props: NewButtonAProps) => (
    <AElement href={props.href} target={props.target} fullWidth={props.fullWidth}>
        <NewButtonContainer {...props} />
    </AElement>
)

const LinkElement = styled(Link)`
    display: block;
`

export interface NewButtonLinkProps extends Props {
    href: string
    rel?: string
}

export const NewButtonLink = (props: NewButtonLinkProps) => (
    <LinkElement href={props.href} passHref prefetch={false} legacyBehavior>
        <a className={props.className}>
            <NewButtonContainer {...props} onClick={props.onClick} />
        </a>
    </LinkElement>
)

export default NewButton
