import { CountryCode } from 'interfaces/Country'
import PolishFlagIcon from 'assets/homepage/polish_flag.svg'
import AustriaFlagIcon from 'assets/homepage/austria_flag.svg'
import GermanFlagIcon from 'assets/homepage/germany_flag.svg'
import FranceFlagIcon from 'assets/homepage/france_flag.svg'
import SwitzerlandFlagIcon from 'assets/homepage/switzerland_flag.svg'
import ItalyFlagIcon from 'assets/homepage/small_italy_flag.svg'
import UnitedKingdomFlagIcon from 'assets/homepage/uk_flag.svg'
import ColombiaFlagIcon from 'assets/homepage/co_flag.svg'
import UnitedStatesFlagIcon from 'assets/homepage/united_states_flag.svg'
import SpainFlagIcon from 'assets/homepage/spain_flag.svg'
import TurkeyFlagIcon from 'assets/homepage/turkey_flag.svg'
import { ParsedLocale } from '../constants/localization'
import { Language } from 'interfaces/Language'

export function getCountryByDomain(): CountryCode | null {
    const domain = document.location.host
    if (domain === 'knowunity.de' || domain === 'de.knowunity.dev') {
        return CountryCode.Germany
    } else if (domain === 'knowunity.fr' || domain === 'fr.knowunity.dev') {
        return CountryCode.France
    } else if (domain === 'knowunity.pl' || domain === 'pl.knowunity.dev') {
        return CountryCode.Poland
    } else if (domain === 'knowunity.it' || domain === 'it.knowunity.dev') {
        return CountryCode.Italy
    } else if (domain === 'knowunity.co.uk' || domain === 'gb.knowunity.dev') {
        return CountryCode.UnitedKingdom
    } else if (domain === 'knowunity.com' || domain === 'us.knowunity.dev') {
        return CountryCode.UnitedStates
    } else if (domain === 'knowunity.es' || domain === 'es.knowunity.dev') {
        return CountryCode.Spain
    } else if (domain === 'knowunity.co' || domain === 'co.knowunity.dev') {
        return CountryCode.Colombia
    } else if (domain === 'knowunity.com.tr' || domain === 'tr.knowunity.dev') {
        return CountryCode.Turkey
    }
    return null
}

export function getCountryByDomainServerSide(domain: string): CountryCode | null {
    if (domain === 'knowunity.de' || domain === 'de.knowunity.dev') {
        return CountryCode.Germany
    } else if (domain === 'knowunity.fr' || domain === 'fr.knowunity.dev') {
        return CountryCode.France
    } else if (domain === 'knowunity.pl' || domain === 'pl.knowunity.dev') {
        return CountryCode.Poland
    } else if (domain === 'knowunity.it' || domain === 'it.knowunity.dev') {
        return CountryCode.Italy
    } else if (domain === 'knowunity.co.uk' || domain === 'gb.knowunity.dev') {
        return CountryCode.UnitedKingdom
    } else if (domain === 'knowunity.com' || domain === 'us.knowunity.dev') {
        return CountryCode.UnitedStates
    } else if (domain === 'knowunity.es' || domain === 'es.knowunity.dev') {
        return CountryCode.Spain
    } else if (domain === 'knowunity.co' || domain === 'co.knowunity.dev') {
        return CountryCode.Colombia
    } else if (domain === 'knowunity.com.tr' || domain === 'tr.knowunity.dev') {
        return CountryCode.Turkey
    }
    return null
}

export function getCountryFlagIcon(countryCode: CountryCode) {
    switch (countryCode) {
        case CountryCode.Germany:
            return <GermanFlagIcon />
        case CountryCode.Switzerland:
            return <SwitzerlandFlagIcon />
        case CountryCode.Austria:
            return <AustriaFlagIcon />
        case CountryCode.Poland:
            return <PolishFlagIcon />
        case CountryCode.France:
            return <FranceFlagIcon />
        case CountryCode.UnitedStates:
            return <UnitedStatesFlagIcon />
        case CountryCode.UnitedKingdom:
            return <UnitedKingdomFlagIcon />
        case CountryCode.Italy:
            return <ItalyFlagIcon />
        case CountryCode.Spain:
            return <SpainFlagIcon />
        case CountryCode.Colombia:
            return <ColombiaFlagIcon />
        case CountryCode.Turkey:
            return <TurkeyFlagIcon />
        default:
            throw new Error(`Unknown countryCode: ${countryCode}`)
    }
}

export const isCountryUS = (parsedLocale: ParsedLocale) =>
    parsedLocale.countryCode === CountryCode.UnitedStates || parsedLocale.interfaceLanguageCode === Language.AmericanEnglish
