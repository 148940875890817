import React, { useEffect, useMemo, useRef, useState } from 'react'
import theme from 'stories/utils/theme'
import styled, { css } from 'styled-components'
import { Body1 } from '../../stories/elements/Typography/Text'
import Icon from 'assets/icons/search-big.svg'
import SearchService from 'services/SearchService'
import { ApiError } from 'services/ApiService'
import _useTranslation from 'lib/hooks/_useTranslation'
import useDebounce from 'lib/hooks/useDebounce'
import { SearchSuggestion } from 'interfaces/Search'
import { colorToCssFilter } from 'lib/features/color'
import { KeyCode } from 'stories/utils/constants/KeyCode'
import { trackEvent } from 'lib/tracking/analytics-service'
import {
    AnalyticsEventSearch,
    AnalyticsEventSearchStartTyping,
    AnalyticsEventSearchSuggestion,
} from 'lib/constants/AnalyticsEventType'
import { generateDefaultSearchSuggestion } from 'lib/features/search'
import { parseLocale } from 'lib/constants/localization'
import { useRouter } from 'next/router'
import { getReferrerScreen } from 'lib/formatting/url'
import { uuidv4 } from 'lib/tracking/attribution/uuid'
import { FormGroup } from './Inputs/Input.css'

const SearchIcon = styled((props) => <Icon {...props} />)<{
    isLocatedWithinModal?: boolean
    highlight?: boolean
}>`
    position: absolute;
    left: ${({ highlight }) => (highlight ? 17.5 : 15)}px;
    top: ${({ isLocatedWithinModal, highlight }) => (highlight ? 30 : isLocatedWithinModal ? 16.5 : 22.5)}px;
`

const Container = styled.div<{ highlight?: boolean }>`
    margin-top: 0px;
    display: grid;
    grid-template-columns: 3fr auto;
    grid-gap: 5px;
    align-items: center;
    padding-left: 5px;
    min-width: 325px;
    * {
        font-family: 'Inter', sans-serif;
    }
    && input {
        height: 45px !important;
        min-height: 45px !important;

        ${({ highlight }) =>
            highlight
                ? css`
                      border: 2px solid ${theme.colors.contentBody}33;
                      height: 60px !important;
                      min-height: 60px !important;
                  `
                : ''}
        ::placeholder {
            color: ${theme.colors.placeholderText} !important;
            opacity: 1;
            font-family: 'Inter', sans-serif;
            font-weight: 400 !important;
            font-size: 16px;
        }
    }

    .icon-button {
        text-indent: 25px;
        background: #fff !important;
    }
`

const OptionsWrapper = styled.div<{ subjectColor?: string; hideDialogLayout?: boolean; highlight?: boolean }>`
    height: fit-content;
    position: absolute;
    bottom: 0;
    border: 1px solid ${theme.colors.violet};
    background-color: ${theme.colors.white};
    width: 100%;
    top: ${({ highlight }) => (highlight ? 67 : 52)}px;
    border-radius: ${theme.borderRadius.normal};
    display: grid;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    z-index: ${theme.zIndex.modal};
    ${({ hideDialogLayout }) =>
        hideDialogLayout
            ? css`
                  box-shadow: none;
                  border: none;
                  border-radius: 0;
                  top: 60px;
              `
            : ''}
`

const Suggestion = styled(Body1)<{
    subjectColor?: string
    isSelected?: boolean
    isTheOnlySuggestion?: boolean
    hideDialogLayout?: boolean
}>`
    img {
        position: absolute;
        left: 15px;
        filter: ${({ subjectColor }) => (subjectColor ? colorToCssFilter(subjectColor) : theme.colors.deepSeaBlue)};
    }
    height: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    cursor: pointer;
    padding: 15px 15px 15px 45px !important;
    font-weight: 400;
    color: ${theme.colors.subtitleBlack} !important;
    :hover {
        background-color: ${theme.colors.violet};
    }
    ${({ isSelected }) =>
        isSelected
            ? css`
                  background-color: ${theme.colors.violet};
              `
            : ''}
    :first-child {
        border-radius: ${theme.borderRadius.normal} ${theme.borderRadius.normal} 0 0;
    }
    :last-child {
        border-radius: 0 0 ${theme.borderRadius.normal} ${theme.borderRadius.normal};
    }
    ${({ isTheOnlySuggestion }) =>
        isTheOnlySuggestion
            ? css`
                  border-radius: ${theme.borderRadius.normal} !important;
              `
            : ''}
    ${({ hideDialogLayout }) =>
        hideDialogLayout
            ? css`
                  :first-child {
                      border-top: none;
                  }
                  box-shadow: none;
                  border-top: 1px solid ${theme.colors.violet};
                  border-radius: 0 !important;
                  background-color: ${theme.colors.white};
                  :hover {
                      background-color: ${theme.colors.white};
                  }
              `
            : ''}
`

interface Props {
    onSuggestionClicked: (searchSuggestion: SearchSuggestion) => void
    placeholder?: string
    defaultValue?: string | null
    isSmall?: boolean
    searchSessionUuid?: string
    origin: string
    hideDialogLayout?: boolean
    referrerScreen?: string
    highlight?: boolean
    autoFocus?: boolean
}

const SearchRecommendations = (props: Props) => {
    const [searchSuggestions, setSearchSuggestions] = useState<SearchSuggestion[] | null>(null)
    const [showOptionsWrapper, setShowOptionWrapper] = useState(false)
    const [selectedSearchResult, setSelectedSearchResult] = useState(-1)
    const [wasOnStartedTypingEventCalled, setWasOnStartedTypingEventCalled] = useState(false)
    const [value, setValue] = useState(props.defaultValue || '')
    const inputRef = useRef<HTMLInputElement>(null)
    const modalRef = useRef<HTMLDivElement>(null)
    const router = useRouter()
    const { locale } = router
    const referrerScreen = props.referrerScreen ?? getReferrerScreen(router)
    const parsedLocale = parseLocale(locale)
    const { t: tCommon } = _useTranslation('common')

    const debouncedSearchValue = useDebounce(value, 200)
    const searchSessionUuid = useMemo(() => props.searchSessionUuid ?? uuidv4(), [props.searchSessionUuid])

    useEffect(() => {
        fetchSuggestions(debouncedSearchValue)
    }, [debouncedSearchValue])

    useEffect(() => {
        if (!props.defaultValue) return
        setValue(props.defaultValue)
        if (inputRef.current) inputRef.current.value = props.defaultValue
    }, [props.defaultValue])

    const fetchSuggestions = (searchQuery: string) =>
        SearchService.getSuggestions(searchQuery, parsedLocale.contentLanguageCode)
            .then((suggestions) => {
                setSearchSuggestions(suggestions)
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknownError(tCommon, 'search.getSuggestions')
                } else {
                    throw error
                }
            })

    const showOptions = () => {
        setShowOptionWrapper(true)
    }

    const hideOptions = () => {
        if (props.hideDialogLayout) return

        setShowOptionWrapper(false)
    }

    const onStartedTyping = () => {
        if (wasOnStartedTypingEventCalled) return

        trackEvent(AnalyticsEventSearchStartTyping, { referrerScreen })
        setWasOnStartedTypingEventCalled(true)
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value
        setValue(query)
        onStartedTyping()
        if (!showOptionsWrapper) showOptions()
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            if (!searchSuggestions || searchSuggestions.length < 0) return
            const { text } = searchSuggestions[selectedSearchResult] || {}
            handleSubmit(generateDefaultSearchSuggestion(text ?? value, {}), 0)
        }
    }

    const handleSubmit = (searchSuggestion: SearchSuggestion, searchPosition: number) => {
        const { text: value } = searchSuggestion
        hideOptions()
        if (inputRef.current) inputRef.current.value = value
        setValue(value)
        trackEvent(AnalyticsEventSearch, {
            value: searchSuggestion.text,
            referrerScreen,
            search: {
                suggestion: searchSuggestion,
                resultPosition: searchPosition,
                origin: props.origin,
                searchSessionUuid,
            },
        })
        props.onSuggestionClicked(searchSuggestion)
        setSelectedSearchResult(-1)
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleMouseDown)
        return () => {
            document.removeEventListener('mousedown', handleMouseDown)
        }
    }, [])

    const handleMouseDown = (event: MouseEvent) => {
        if (!event.target) return

        const isClickOutside = !modalRef.current?.contains(event.target as Element)
        const isInputClicked = inputRef.current?.contains(event.target as Element)
        if (isClickOutside && !isInputClicked) {
            hideOptions()
        }
    }

    useEffect(() => {
        if (!searchSuggestions?.length) return
        const suggestion = searchSuggestions[selectedSearchResult]
        if (inputRef.current) inputRef.current.value = suggestion?.text ?? value
    }, [selectedSearchResult])

    useEffect(() => {
        const trackKeyEvent = (event: KeyboardEvent) => {
            if (event.keyCode === KeyCode.up) {
                setSelectedSearchResult((selectedIndex) => (selectedIndex === 0 ? -1 : --selectedIndex))
                return
            }
            if (event.keyCode === KeyCode.down) {
                setSelectedSearchResult((selectedIndex) => ++selectedIndex)
                return
            }
        }

        document.addEventListener('keydown', (event) => trackKeyEvent(event))
        return () => {
            document.removeEventListener('keydown', (event) => trackKeyEvent(event))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showOptionsModal = (searchSuggestions?.length || value?.length) && showOptionsWrapper

    const onSearchSuggestionClicked = (searchSuggestion: SearchSuggestion, resultPosition: number) => {
        trackEvent(AnalyticsEventSearchSuggestion, {
            value: searchSuggestion.text,
            referrerScreen,
            search: {
                suggestion: searchSuggestion,
                resultPosition,
                origin: props.origin,
                searchSessionUuid,
            },
        })
        handleSubmit(searchSuggestion, resultPosition)
    }

    return (
        <Container highlight={props.highlight}>
            <FormGroup noMargin height={10} isRelative>
                <SearchIcon highlight={props.highlight} className="input-icon" />
                <input
                    role="search"
                    ref={inputRef}
                    onClick={showOptions}
                    onChange={handleChange}
                    defaultValue={props.defaultValue || ''}
                    onKeyDown={handleKeyDown}
                    placeholder={props.placeholder ?? ''}
                    autoFocus={props.autoFocus}
                    className="icon-button"
                />
                {showOptionsModal ? (
                    <OptionsWrapper ref={modalRef} hideDialogLayout={props.hideDialogLayout} highlight={props.highlight}>
                        {value?.length ? (
                            <Suggestion
                                fontFamily="Inter"
                                className="option"
                                fontWeigth={400}
                                onClick={() => handleSubmit(generateDefaultSearchSuggestion(value, {}), 0)}
                                isSelected={selectedSearchResult === -1}
                                isTheOnlySuggestion={!searchSuggestions?.length}
                                hideDialogLayout={props.hideDialogLayout}
                            >
                                <SearchIcon isSmall={props.isSmall} isLocatedWithinModal />
                                {value}
                            </Suggestion>
                        ) : null}
                        {searchSuggestions?.map((suggestion, index) => (
                            <Suggestion
                                fontFamily="Inter"
                                onClick={() => onSearchSuggestionClicked(suggestion, index + 1)}
                                key={suggestion.text}
                                subjectColor={suggestion.subject?.color}
                                isSelected={selectedSearchResult === index}
                                hideDialogLayout={props.hideDialogLayout}
                            >
                                {suggestion.type === 'SUBJECT' && suggestion.subject ? (
                                    <img src={suggestion.subject.iconUrl} alt="-" width={20} height={20} />
                                ) : (
                                    <SearchIcon isSmall={props.isSmall} isLocatedWithinModal />
                                )}
                                {suggestion.text}
                            </Suggestion>
                        ))}
                    </OptionsWrapper>
                ) : null}
            </FormGroup>
        </Container>
    )
}

export default SearchRecommendations
