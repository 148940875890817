import { Dispatch, SetStateAction, useRef } from 'react'
import styled from 'styled-components'
import { Body2 } from 'stories/elements/Typography/Text'
import ExpandArrowDown from 'assets/icons/menu_arrow.svg'
import ExpandArrowDownDark from 'assets/icons/menu_arrow_dark.svg'
import HamburgerFilledIcon from 'assets/header/hamburger-menu-filled.svg'
import HamburgerOutlineIcon from 'assets/header/hamburger-menu.svg'
import theme from 'stories/utils/theme'
import Row from 'components/elements/Row'
import useMedia from 'lib/hooks/useMedia'
import { deviceSize } from 'lib/constants/GridBreakpoints'
import _useTranslation from 'lib/hooks/_useTranslation'

const Container = styled.div<{ isLandingPage?: boolean; isSelected?: boolean }>`
    display: flex;
    align-items: center;

    height: 100%;
    padding: 10px 0;
    .select {
        cursor: pointer;
        svg {
            transition-duration: 0.3s;
            transition-property: transform;
            transform: ${({ isSelected }) => `rotate(${isSelected ? 0 : 180}deg)`};
        }
        svg path {
            fill: ${({ isLandingPage }) => (isLandingPage ? theme.colors.white : `${theme.colors.subtitleBlack}aa`)};
        }
    }
    .center-menu {
        cursor: pointer;
        @media (max-width: 1150px) {
            display: flex;
            align-items: center;
            height: 100%;
        }
        display: none;
    }
`

interface Props {
    isLandingPage?: boolean
    isMenuOptionOpen: boolean
    changeMenuState: Dispatch<SetStateAction<boolean>>
}

const MenuOption = ({ isLandingPage, isMenuOptionOpen, changeMenuState }: Props) => {
    const floatingMenuRef = useRef<HTMLDivElement>(null)

    const tablet = useMedia(deviceSize.tablet)

    const { t: tCommon } = _useTranslation('common')

    const onMenuClicked = () => {
        changeMenuState((val) => !val)
    }

    return (
        <Container isLandingPage={isLandingPage} isSelected={isMenuOptionOpen} ref={floatingMenuRef}>
            <div className="tablet">
                <Row gap={10} className="select" onClick={onMenuClicked}>
                    <Body2 fontWeigth={600} color={isLandingPage ? theme.colors.white : theme.colors.contentBody}>
                        {tablet ? tCommon('common/menu') : tCommon('common/navBarMenuSubjectMenuItem')}
                    </Body2>
                    {isLandingPage ? <ExpandArrowDown /> : <ExpandArrowDownDark />}
                </Row>
            </div>
            <div className="center-menu" onClick={onMenuClicked}>
                {isLandingPage ? <HamburgerFilledIcon /> : <HamburgerOutlineIcon />}
            </div>
        </Container>
    )
}

export default MenuOption
