import ApiService from './ApiService'
import { Knower, KnowerStatusStats } from 'interfaces/Knower'
import store from '../redux/store'
import { setKnower } from '../redux/actions'

const KnowerService = {
    get(knowerUuid: string) {
        return ApiService.get(`knowers/${knowerUuid}`).then((response) => {
            return response.data as Knower
        })
    },

    getMe() {
        return ApiService.get('knowers/me').then((response) => {
            store().dispatch(setKnower(response.data))
            return response.data as Knower
        })
    },

    getUploadsStatusStats(knowerUuid: string) {
        return ApiService.get(`/knowers/${knowerUuid}/knows/status-stats`).then((response) => {
            return response.data as KnowerStatusStats
        })
    },
}

export default KnowerService
