import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import HamburgerFilledIcon from 'assets/header/hamburger-menu-filled.svg'
import HamburgerOutlineIcon from 'assets/header/hamburger-menu.svg'
import { Body1, Body2 } from 'stories/elements/Typography/Text'
import ExpandArrowDown from 'assets/icons/menu_arrow.svg'
import ExpandArrowDownDark from 'assets/icons/menu_arrow_dark.svg'
import theme from 'stories/utils/theme'
import DynamicLink from '../LandingPage/DynamicLink'
import Row from 'components/elements/Row'
import { getCountryConfig } from 'lib/constants/countryconfig'
import { HeaderCategory, getMenuCategories } from 'lib/constants/headerRoutes'
import { parseLocale } from 'lib/constants/localization'
import { useRouter } from 'next/router'
import useMedia from 'lib/hooks/useMedia'
import { deviceSize } from 'lib/constants/GridBreakpoints'
import { ClientOnly } from 'components/elements/ClientOnly'
import _useTranslation from 'lib/hooks/_useTranslation'
import { appFeedsRoute, proPlanRoute } from 'lib/constants/routes'
import ProPlanIcon from 'assets/app/sidebar/plus_plan.svg'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/reducer'
import HomeIcon from 'assets/app/sidebar/home.svg'

const Container = styled.div<{ isLandingPage?: boolean; isSelected?: boolean }>`
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    height: 100%;
    padding: 10px 0;

    .select {
        svg {
            transition-duration: 0.3s;
            transition-property: transform;
            transform: ${({ isSelected }) => `rotate(${isSelected ? 0 : 180}deg)`};
        }
        svg path {
            fill: ${({ isLandingPage }) => (isLandingPage ? theme.colors.white : `${theme.colors.subtitleBlack}aa`)};
        }
    }
    .center-menu {
        @media (max-width: 1150px) {
            display: flex;
            align-items: center;
            height: 100%;
        }
        display: none;
    }
`

const DropdownMenu = styled.div`
    position: absolute;
    top: 60px;
    width: 265px;
    height: auto;
    border-radius: ${theme.borderRadius.normal};
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: ${theme.colors.white};
    z-index: ${theme.zIndex.navBarUserDropdown};
    p {
        user-select: none;
        -o-user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
    }
    @media (max-width: 1150px) {
        width: 100%;
        top: 70px;
        left: 0;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 0 0 ${theme.borderRadius.normal} ${theme.borderRadius.normal};
    }
    @media (max-width: 750px) {
        top: 60px;
    }
    .title {
        padding: 15px;
        border-bottom: 1px solid ${theme.colors.contentBody}33;
        cursor: auto;
    }
    .item {
        display: flex;
        gap: 15px;
        align-items: center;
    }
    a {
        display: flex;
        align-items: center;
        height: 35px;
        padding: 30px 5px 30px 25px;
        border-radius: 0;
        border-bottom: 1px solid ${theme.colors.contentBody}33;

        :hover {
            background-color: ${theme.colors.contentBody}11;
        }
        :first-child {
            border-radius: ${theme.borderRadius.large} ${theme.borderRadius.large} 0 0;
        }
        :last-child {
            border-radius: 0 0 0 ${theme.borderRadius.large};
            border-bottom: none;
        }
    }
`

interface Props {
    isLandingPage?: boolean
    showCompanyOptions?: boolean
    hasContentCampaignRunning: boolean
}

const HeaderFloatingMenu = ({ isLandingPage, showCompanyOptions, hasContentCampaignRunning }: Props) => {
    const floatingMenuRef = useRef<HTMLDivElement>(null)
    const [openFloatingMenu, setFloatingMenu] = useState(false)
    const { locale } = useRouter()
    const large = useMedia(deviceSize.lg)
    const isMobile = useMedia(deviceSize.tabletVertical)
    const { t: tCommon } = _useTranslation('common')
    const parsedLocale = parseLocale(locale)
    const countryConfig = getCountryConfig(parsedLocale.countryCode)
    const menuItems = getMenuCategories(countryConfig, tCommon)
    const category = showCompanyOptions || hasContentCampaignRunning ? HeaderCategory.Company : HeaderCategory.Learning
    const filteredMenuItems = large ? menuItems : menuItems.filter((menu) => menu.category === category)
    const showProRoute = isMobile && countryConfig.hasPlusPlan
    const authenticatedUser = useSelector((state: AppState) => state.user)

    useEffect(() => {
        document.addEventListener('mousedown', handleMouseDown)
        return () => {
            document.removeEventListener('mousedown', handleMouseDown)
        }
    }, [])

    const handleMouseDown = (event: MouseEvent) => {
        const isClickOutside = !floatingMenuRef.current?.contains(event.target as Node)

        if (isClickOutside) {
            setFloatingMenu(false)
        }
    }

    const handleOptionClicked = () =>
        setTimeout(() => {
            setFloatingMenu(false)
        }, 250)

    const onMenuClicked = () => setFloatingMenu((value) => !value)

    if (large) return null

    return (
        <Container isLandingPage={isLandingPage} isSelected={openFloatingMenu} ref={floatingMenuRef} onClick={onMenuClicked}>
            <div className="tablet">
                <Row gap={10} className="select">
                    <Body2 fontWeigth={600} color={isLandingPage ? theme.colors.white : theme.colors.contentBody}>
                        {large || hasContentCampaignRunning
                            ? tCommon('common/menu')
                            : showCompanyOptions
                            ? tCommon('common/navBarMoreLink')
                            : tCommon('common/navBarMenuSubjectMenuItem')}
                    </Body2>
                    {isLandingPage ? <ExpandArrowDown /> : <ExpandArrowDownDark />}
                </Row>
            </div>

            <div className="center-menu">{isLandingPage ? <HamburgerFilledIcon /> : <HamburgerOutlineIcon />}</div>
            <ClientOnly>
                {openFloatingMenu ? (
                    <DropdownMenu onClick={handleOptionClicked}>
                        {showProRoute ? (
                            <DynamicLink href={proPlanRoute} prefetch={false}>
                                <div className="item">
                                    <ProPlanIcon />
                                    <Body1 fontFamily="Inter" fontWeigth={600} color={theme.colors.headlineDark}>
                                        Knowunity Pro
                                    </Body1>
                                </div>
                            </DynamicLink>
                        ) : null}
                        {authenticatedUser && !showCompanyOptions ? (
                            <DynamicLink href={appFeedsRoute} prefetch={false}>
                                <div className="item">
                                    <HomeIcon />
                                    <Body1 fontFamily="Inter" fontWeigth={500} color={theme.colors.headlineDark}>
                                        {tCommon('common/navBarHomeLink')}
                                    </Body1>
                                </div>
                            </DynamicLink>
                        ) : null}
                        {filteredMenuItems.map(({ title, dropdownItems }) => (
                            <>
                                {large ? (
                                    <div className="title">
                                        <Body1 fontFamily="Inter" fontWeigth={600} color={theme.colors.headlineDark}>
                                            {title}
                                        </Body1>
                                    </div>
                                ) : null}
                                {dropdownItems.map((item) =>
                                    item.show ? (
                                        <>
                                            <DynamicLink href={item.to} key={`menu-item-${item}`} prefetch={false}>
                                                <div className="item">
                                                    {item.icon}
                                                    <Body1
                                                        fontFamily="Inter"
                                                        fontWeigth={400}
                                                        color={theme.colors.contentBody}
                                                    >
                                                        {item.name}
                                                    </Body1>
                                                </div>
                                            </DynamicLink>
                                        </>
                                    ) : null
                                )}
                            </>
                        ))}
                    </DropdownMenu>
                ) : null}
            </ClientOnly>
        </Container>
    )
}

export default HeaderFloatingMenu
