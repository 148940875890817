import { Router } from 'next/router'

export interface PageTrackingSnippet {
    name: string
    path: string
    previousName: string | null
    previousPath: string | null
}

let previousPageName: PageTrackingSnippet['previousName'] = null
let previousPagePath: PageTrackingSnippet['previousPath'] = null
let cachedPageSnippet: PageTrackingSnippet | null = null
let pageHaveChange = false

export function getPageTrackingSnippet(): PageTrackingSnippet | null {
    if (cachedPageSnippet && !pageHaveChange) {
        return cachedPageSnippet
    }

    Router.events.on('routeChangeStart', () => {
        pageHaveChange = true
    })

    const formatQuery = (url: string) => url.split('?')[0]

    const snippet = {
        name: formatQuery(window.history.state.url ?? ''),
        path: window.location.pathname,
        previousName: previousPageName,
        previousPath: previousPagePath,
    }

    cachedPageSnippet = snippet
    previousPagePath = snippet.path
    previousPageName = snippet.name
    pageHaveChange = false

    return snippet
}

export const initiatePageTracking = () => getPageTrackingSnippet()
