import UAParser from 'ua-parser-js'

export const isDeviceMobile = () => {
    const parser = new UAParser()
    const { name } = parser.getOS()

    if (name === 'Android' || name == 'iOS') {
        return true
    }

    return false
}
