export interface Country {
    id: number
    name: string
    code: CountryCode
}

export enum CountryCode {
    Germany = 'DE',
    Switzerland = 'CH',
    Austria = 'AT',
    France = 'FR',
    Poland = 'PL',
    Italy = 'IT',
    UnitedKingdom = 'GB',
    UnitedStates = 'US',
    Spain = 'ES',
    Colombia = 'CO',
    Turkey = 'TR',
}
