import _useTranslation from 'lib/hooks/_useTranslation'
import { useToggleState } from 'lib/hooks/useToggleState'
import { Body2 } from 'stories/elements/Typography/Text'
import styled from 'styled-components'
import Icon from 'assets/icons/search.svg'
import theme from 'stories/utils/theme'
import FeatureDialog from 'stories/elements/Modal/DialogWindow/FeatureDialog'
import SearchBarRedirect from 'components/feature/App/widgets/SearchBarRedirect'
import CloseIcon from 'assets/icons/close.svg'
import { useRouter } from 'next/router'
import { trackEvent } from 'lib/tracking/analytics-service'
import { getReferrerScreen } from 'lib/formatting/url'
import { AnalyticsEventMobileSearchBarDialogClick } from 'lib/constants/AnalyticsEventType'

const Container = styled.div<{ color: string }>`
    width: 100%;

    .search-icon {
        padding-left: 5px;
        cursor: pointer;
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        svg {
            fill: ${({ color }) => color ?? theme.colors.deepSeaBlue};
        }
    }
`

const RowLayout = styled.div`
    display: grid;
    position: relative;
    padding-top: 22.5px;
    &:first-child > svg {
        cursor: pointer;
        position: absolute;
        right: 0;
    }
`

interface Props {
    isHomepage: boolean
}

const MobileSearchBar = ({ isHomepage }: Props) => {
    const [showMobileSearchDialog, toggleMobileSearchDialog] = useToggleState(false)
    const { t: tCommon } = _useTranslation('common')
    const router = useRouter()
    const referrerScreen = getReferrerScreen(router)

    const color = isHomepage ? theme.colors.white : theme.colors.deepSeaBlue

    const onSearchIconClicked = () => {
        toggleMobileSearchDialog()
        trackEvent(AnalyticsEventMobileSearchBarDialogClick, { referrerScreen })
    }

    return (
        <Container color={color}>
            <div className="search-icon" onClick={onSearchIconClicked}>
                <Icon />
                <Body2 fontFamily="Inter" fontWeigth={600} color={color}>
                    {tCommon('common/searchButtonLabel')}
                </Body2>
            </div>
            {showMobileSearchDialog && (
                <FeatureDialog
                    maxWidth={1250}
                    title=""
                    hideTitle
                    onClose={toggleMobileSearchDialog}
                    ariaPrefix="mobile-search"
                    fullScreen
                    dialogIcon={<Icon />}
                    open={showMobileSearchDialog}
                    hideCloseButton
                >
                    <RowLayout>
                        <CloseIcon onClick={toggleMobileSearchDialog} />
                        <SearchBarRedirect
                            referrerScreen="mobile-header"
                            hideDialogLayout
                            onSearchOption={toggleMobileSearchDialog}
                        />
                    </RowLayout>
                </FeatureDialog>
            )}
        </Container>
    )
}

export default MobileSearchBar
