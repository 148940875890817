export enum Language {
    German = 'de',
    French = 'fr',
    BritishEnglish = 'en-GB',
    Polish = 'pl',
    Italian = 'it',
    AmericanEnglish = 'en',
    Spanish = 'es',
    ColombiaSpanish = 'es-CO',
    Turkish = 'tr',
}

export interface LanguageInterface {
    id: number
    name: string
    code: Language
}

export interface LanguageOption {
    data: LanguageInterface
    label: string
}
