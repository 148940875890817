import { CountryCode } from 'interfaces/Country'
import { Currency } from 'interfaces/Currency'
import { CountryLanguageConfig } from 'lib/constants/countryLanguageConfig'

export const isExpansionContentCompetitionLive = ({
    expansionContestStartOn,
    expansionContestEndOn,
}: CountryLanguageConfig) => {
    if (!expansionContestStartOn) return false
    return (
        new Date() >= new Date(expansionContestStartOn) &&
        (!expansionContestEndOn || new Date() <= new Date(expansionContestEndOn))
    )
}

export function getCountryExpansionKnowTiers(countryCode: CountryCode) {
    switch (countryCode) {
        case CountryCode.Germany:
        case CountryCode.Switzerland:
        case CountryCode.Austria:
        case CountryCode.Poland:
        case CountryCode.France:
        case CountryCode.UnitedKingdom:
        case CountryCode.Italy:
        case CountryCode.UnitedStates:
        case CountryCode.Spain:
            return null
        case CountryCode.Colombia:
            return [
                {
                    payoutAmount: 200000,
                    knowsRequirement: '50+',
                    bottomLimit: 50,
                    topLimit: null,
                    currency: Currency.COP,
                },
                {
                    payoutAmount: 180000,
                    knowsRequirement: '36-50',
                    bottomLimit: 36,
                    topLimit: 51,
                    currency: Currency.COP,
                },
                {
                    payoutAmount: 160000,
                    knowsRequirement: '26-35',
                    bottomLimit: 26,
                    topLimit: 36,
                    currency: Currency.COP,
                },
                {
                    payoutAmount: 140000,
                    knowsRequirement: '16-25',
                    bottomLimit: 16,
                    topLimit: 26,
                    currency: Currency.COP,
                },
                {
                    payoutAmount: 120000,
                    knowsRequirement: '6-15',
                    bottomLimit: 6,
                    topLimit: 16,
                    currency: Currency.COP,
                },
                {
                    payoutAmount: 100000,
                    knowsRequirement: '1-5',
                    bottomLimit: 0,
                    topLimit: 6,
                    currency: Currency.COP,
                },
            ]
        case CountryCode.Turkey:
            return [
                {
                    payoutAmount: 3200,
                    knowsRequirement: '50+',
                    bottomLimit: 50,
                    topLimit: null,
                    currency: Currency.TRY,
                },
                {
                    payoutAmount: 3000,
                    knowsRequirement: '36-50',
                    bottomLimit: 36,
                    topLimit: 51,
                    currency: Currency.TRY,
                },
                {
                    payoutAmount: 2500,
                    knowsRequirement: '26-35',
                    bottomLimit: 26,
                    topLimit: 36,
                    currency: Currency.TRY,
                },
                {
                    payoutAmount: 2200,
                    knowsRequirement: '16-25',
                    bottomLimit: 16,
                    topLimit: 26,
                    currency: Currency.TRY,
                },
                {
                    payoutAmount: 2000,
                    knowsRequirement: '6-15',
                    bottomLimit: 6,
                    topLimit: 16,
                    currency: Currency.TRY,
                },
                {
                    payoutAmount: 1500,
                    knowsRequirement: '1-5',
                    bottomLimit: 0,
                    topLimit: 6,
                    currency: Currency.TRY,
                },
            ]
        default:
            throw new Error(`Unknown countryCode: ${countryCode}`)
    }
}
