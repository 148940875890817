import { ClientOnly } from 'components/elements/ClientOnly'
import config from 'lib/config'
import { AnalyticsEventFacebookMessengerHide, AnalyticsEventFacebookMessengerOpen } from 'lib/constants/AnalyticsEventType'
import theme from 'stories/utils/theme'
import { trackEvent } from 'lib/tracking/analytics-service'
import { getReferrerScreen } from 'lib/formatting/url'
import { useRouter } from 'next/router'
import { MessengerChat } from 'react-messenger-chat-plugin'
import { parseLocale } from 'lib/constants/localization'
import { getPageWithoutMessengerWidget } from 'lib/constants/navbar'
import { getCountryConfig } from 'lib/constants/countryconfig'

const FacebookMessengerWidget = () => {
    const router = useRouter()
    const referrerScreen = getReferrerScreen(router)
    const parsedLocale = parseLocale(router.locale)
    const { hasFacebookMessengerWidget } = getCountryConfig(parsedLocale.countryCode)
    const pagesWithoutMessengerWidget = getPageWithoutMessengerWidget(parsedLocale.contentLanguageCode)
    const shouldMessengerWidgetBeShown = hasFacebookMessengerWidget && !pagesWithoutMessengerWidget.includes(router.pathname)

    return (
        <ClientOnly>
            {shouldMessengerWidgetBeShown ? (
                <MessengerChat
                    pageId={config.getFacebookPageID()}
                    language="en_US"
                    themeColor={theme.colors.knowunityBlue}
                    loggedInGreeting={''}
                    loggedOutGreeting={''}
                    onMessengerDialogShow={() => {
                        trackEvent(AnalyticsEventFacebookMessengerOpen, { referrerScreen })
                    }}
                    onMessengerDialogHide={() => {
                        trackEvent(AnalyticsEventFacebookMessengerHide, { referrerScreen })
                    }}
                />
            ) : null}
        </ClientOnly>
    )
}

export default FacebookMessengerWidget
