import { CountryCode } from 'interfaces/Country'
import { Language } from 'interfaces/Language'

export function languageToCountryCode(language: Language) {
    switch (language) {
        case Language.German:
            return CountryCode.Germany
        case Language.French:
            return CountryCode.France
        case Language.Polish:
            return CountryCode.Poland
        case Language.Italian:
            return CountryCode.Italy
        case Language.Spanish:
            return CountryCode.Spain
        case Language.BritishEnglish:
            return CountryCode.UnitedKingdom
        case Language.AmericanEnglish:
            return CountryCode.UnitedStates
        case Language.ColombiaSpanish:
            return CountryCode.Colombia
        case Language.Turkish:
            return CountryCode.Turkey
    }
    throw new Error(`Unknown language ${language}`)
}
