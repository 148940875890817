import { KnowFormat } from 'interfaces/Know'
import ApiService from './ApiService'
import { SearchContent, SearchSuggestion } from 'interfaces/Search'
import { Language } from 'interfaces/Language'

const SearchService = {
    getKnows(
        searchQuery: string,
        limit?: number,
        page?: number,
        subjectId?: number,
        knowFormat?: KnowFormat,
        contentLanguageCode?: Language
    ) {
        return ApiService.get(
            `/search/knows?query=${searchQuery}&contentType=KNOW${limit ? `&limit=${limit}` : ''}${
                page ? `&page=${page}` : ''
            }${subjectId ? `&subject=${subjectId}` : ''}${knowFormat ? `&knowFormat=${knowFormat}` : ''}${
                contentLanguageCode ? `&contentLanguageCode=${contentLanguageCode}` : ''
            }`
        ).then((response) => {
            return {
                knows: response.data.content as SearchContent[],
                hasMore: !response.data.last,
                totalElements: response.data.totalElements,
                page: response.data.page,
                numberOfExactResults: response.data.numberOfExactResults as number,
            }
        })
    },

    getSuggestions(searchQuery: string, contentLanguageCode: Language) {
        return ApiService.get(
            `/search/suggestions?query=${searchQuery}&contentLanguageCode=${contentLanguageCode}${
                contentLanguageCode === Language.AmericanEnglish ? `&variantName=includeKnowTitles` : ''
            }`
        ).then((response) => response.data.content as SearchSuggestion[])
    },
}

export default SearchService
