import { CountryCode } from 'interfaces/Country'
import { Language } from 'interfaces/Language'
import { PrismicLanguage } from 'interfaces/PrismicLanguage'

export interface ParsedLocale {
    countryCode: CountryCode
    contentLanguageCode: Language
    interfaceLanguageCode: Language
    prismicLegalLanguage: PrismicLanguage
    prismicLanguage: PrismicLanguage
}

export enum Locale {
    GermanGermany = 'de',
    FrenchFrance = 'fr',
    EnglishUnitedKingdom = 'en-GB',
    PolishPoland = 'pl',
    ItalianItaly = 'it',
    EnglishUnitedStates = 'en',
    SpainSpanish = 'es',
    TurkeyTurkish = 'tr',
}

export function getLocales(): Record<string, ParsedLocale | undefined> {
    return locales
}

const locales: Record<string, ParsedLocale | undefined> = {
    de: {
        countryCode: CountryCode.Germany,
        contentLanguageCode: Language.German,
        interfaceLanguageCode: Language.German,
        prismicLegalLanguage: 'de-de',
        prismicLanguage: 'de-de',
    },
    fr: {
        countryCode: CountryCode.France,
        contentLanguageCode: Language.French,
        interfaceLanguageCode: Language.French,
        prismicLegalLanguage: 'fr-fr',
        prismicLanguage: 'fr-fr',
    },
    pl: {
        countryCode: CountryCode.Poland,
        contentLanguageCode: Language.Polish,
        interfaceLanguageCode: Language.Polish,
        prismicLegalLanguage: 'pl',
        prismicLanguage: 'pl',
    },
    it: {
        countryCode: CountryCode.Italy,
        contentLanguageCode: Language.Italian,
        interfaceLanguageCode: Language.Italian,
        prismicLegalLanguage: 'it-it',
        prismicLanguage: 'it-it',
    },
    'en-GB': {
        countryCode: CountryCode.UnitedKingdom,
        contentLanguageCode: Language.BritishEnglish,
        interfaceLanguageCode: Language.BritishEnglish,
        prismicLegalLanguage: 'en-gb',
        prismicLanguage: 'en-gb',
    },
    en: {
        countryCode: CountryCode.UnitedStates,
        contentLanguageCode: Language.AmericanEnglish,
        interfaceLanguageCode: Language.AmericanEnglish,
        prismicLegalLanguage: 'en-us',
        prismicLanguage: 'en-us',
    },
    es: {
        countryCode: CountryCode.Spain,
        contentLanguageCode: Language.Spanish,
        interfaceLanguageCode: Language.Spanish,
        prismicLegalLanguage: 'es-es',
        prismicLanguage: 'es-es',
    },
    'es-CO': {
        countryCode: CountryCode.Colombia,
        contentLanguageCode: Language.ColombiaSpanish,
        interfaceLanguageCode: Language.ColombiaSpanish,
        prismicLegalLanguage: 'es-es',
        prismicLanguage: 'es-es',
    },
    tr: {
        countryCode: CountryCode.Turkey,
        contentLanguageCode: Language.Turkish,
        interfaceLanguageCode: Language.Turkish,
        prismicLegalLanguage: 'tr',
        prismicLanguage: 'tr',
    },
}

export function parseLocale(locale?: string): ParsedLocale {
    if (!locale) {
        throw new Error(`Locale not available.`)
    }

    const data = locales[locale]
    if (!data) {
        throw new Error(`Unknown locale '${locale}'`)
    }

    return data
}

export function localizeNumber(number: number, language: Language): string {
    return number.toLocaleString(language)
}
