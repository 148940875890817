import React from 'react'

export const parseTranslationInterpolation = (
    value: string,
    elements: { index: number; component: any; props: Record<string, any> }[]
) => {
    const parts = value.split(/(\[\[|\]\])/).filter((p) => p !== '[[' && p !== ']]')

    const renderPart = (part: string, index: number) => {
        const element = elements.find((e) => index === e.index)
        if (!element) return part

        return (
            <element.component {...element.props} key={index}>
                {part}
            </element.component>
        )
    }

    return <>{parts.map((part, index) => renderPart(part, index))}</>
}

export type TranslationKeys =
    | 'common'
    | 'profile'
    | 'knows'
    | 'profilePayouts'
    | 'auth'
    | 'knowdemands'
    | 'homepage'
    | 'emailPreferences'
    | 'subjects'
    | 'becomeKnower'
    | 'press'
    | 'code'
    | 'referral'
    | 'knowers'
    | 'chat'
    | 'plusPlan'
    | 'app'
    | 'blog'
