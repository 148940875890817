import { getQueryParam } from '../constants/URLs'
import { getActiveExperiments as getActiveExperimentsStore, storeActiveExperiments } from '../tracking/storage'

const QUERY_EXPERIMENT_KEY = 'activateExperiment'

let cachedActiveExperiments: ActiveExperiments | null = null

export type ActiveExperiments = Record<ExperimentKey, ExperimentVariant>

export enum ExperimentKey {
    test = 'TEST',
    deblurFirstKnowPage = 'DEBLUR_FIRST_KNOW_PAGE',
    showStepByStepPADOnKnowPage = 'SHOW_STEP_BY_STEP_PAD_ON_KNOW_PAGE',
}

export enum ExperimentVariant {
    testBaseline = 'TEST_0',
    testVariant = 'TEST_1',
    deblurFirstKnowPageVariant0 = 'DEBLUR_FIRST_KNOW_PAGE_VARIANT_0',
    deblurFirstKnowPageVariant1 = 'DEBLUR_FIRST_KNOW_PAGE_VARIANT_1',
    showStepByStepPADOnKnowPageVariant0 = 'SHOW_STEP_BY_STEP_PAD_KNOW_PAGE_VARIANT_0',
    showStepByStepPADOnKnowPageVariant1 = 'SHOW_STEP_BY_STEP_PAD_KNOW_PAGE_VARIANT_1',
}

function getRandomVariant(variants: ExperimentVariant[]): ExperimentVariant {
    return variants[Math.floor(Math.random() * variants.length)]
}

function getStoredVariant(storedVariants: string[], variants: ExperimentVariant[]): ExperimentVariant | null {
    for (const variant of variants) {
        if (storedVariants.includes(variant)) {
            return variant
        }
    }

    return null
}

function getExperimentVariant(possibleVariants: ExperimentVariant[], storedVariants: string[]): ExperimentVariant {
    const queryExperiment = getExperimentFromQuery()
    const matchingExperimentFromQuery = getExperimentFromQueryMatchingPossibleVariants(queryExperiment, possibleVariants)

    if (matchingExperimentFromQuery) return matchingExperimentFromQuery

    const storedVariant = getStoredVariant(storedVariants, possibleVariants)
    if (storedVariant !== null) {
        return storedVariant
    } else {
        return getRandomVariant(possibleVariants)
    }
}

export function getActiveExperiments(): ActiveExperiments {
    if (cachedExperimentsAreValidIncludingQueryExperiment(cachedActiveExperiments)) {
        return cachedActiveExperiments!
    }

    const storedVariants = getActiveExperimentsStore()
    const activeExperiments: ActiveExperiments = {
        TEST: getExperimentVariant([ExperimentVariant.testBaseline, ExperimentVariant.testVariant], storedVariants),
        DEBLUR_FIRST_KNOW_PAGE: getExperimentVariant(
            [ExperimentVariant.deblurFirstKnowPageVariant0, ExperimentVariant.deblurFirstKnowPageVariant1],
            storedVariants
        ),
        SHOW_STEP_BY_STEP_PAD_ON_KNOW_PAGE: getExperimentVariant(
            [ExperimentVariant.showStepByStepPADOnKnowPageVariant0, ExperimentVariant.showStepByStepPADOnKnowPageVariant1],
            storedVariants
        ),
    }

    storeActiveExperiments(Object.values(activeExperiments))
    cachedActiveExperiments = activeExperiments

    return activeExperiments
}

export function getActiveExperimentsVariants() {
    const activeExperiments = getActiveExperiments()
    return Object.values(activeExperiments)
}

const getExperimentFromQuery = () => {
    let item = getQueryParam(QUERY_EXPERIMENT_KEY)
    if (!item) {
        return null
    }

    // Needed for backward-compatibility
    item = item.replace('"', '')
    return item.split(',') as ExperimentVariant[]
}

const getExperimentFromQueryMatchingPossibleVariants = (
    queryExperiment: ExperimentVariant[] | null,
    possibleVariants: ExperimentVariant[]
) => {
    if (!queryExperiment) return null

    let matchingExperiment = null
    queryExperiment.forEach((experiment) => {
        if (possibleVariants.includes(experiment)) matchingExperiment = experiment
    })
    return matchingExperiment
}

const cachedExperimentsAreValidIncludingQueryExperiment = (cachedExperiments: ActiveExperiments | null) => {
    if (!cachedExperiments) return false
    const queryExperiment = getExperimentFromQuery()

    if (!queryExperiment) return true

    return !!Object.values(cachedExperiments).includes(...(queryExperiment as [ExperimentVariant]))
}
