import type { AppContext, AppProps } from 'next/app'
import 'globals.css'
import { Provider } from 'react-redux'
import { useStore } from 'redux/store'
import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { useRouter } from 'next/router'
import { appWithTranslation, SSRConfig } from 'next-i18next'
import {
    getLandingPageSlugs,
    getPagesWithoutFooter,
    getPagesWithoutNavbarAndFooter,
    shouldEnableClarityForMinorityOfUsers,
} from 'lib/constants/navbar'
import { advertiseRoute, calendlyRoute } from 'lib/constants/routes'
import { useEffect } from 'react'
import { getAttribution } from 'lib/tracking/storage'
import { initiatePageTracking } from 'lib/tracking/pageTracking'
import { State } from 'redux/types'
import { getCountryConfig } from 'lib/constants/countryconfig'
import { getPathWithoutQuery } from 'lib/formatting/url'
import { parseLocale } from 'lib/constants/localization'
import Header from 'components/modules/Homepage/Header'
import NoIndexAlternativeLocaleTag from 'components/modules/SEO/NoIndexTag'
import FacebookMessengerWidget from 'components/modules/LandingPage/FacebookMessengerWidget'
import MicrosoftClarityTag from 'components/modules/LandingPage/MicrosoftClarityTag'
import AuthenticationHandler from 'components/feature/Authentication/AuthenticationHandler'
import CookieBanner from 'components/modules/Banners/CookieBanner'
import dynamic from 'next/dynamic'
import { getCountryLanguageConfig } from 'lib/constants/countryLanguageConfig'
import { isExpansionContentCompetitionLive } from 'lib/features/expansion'

const Footer = dynamic(() => import('components/modules/Footer/Footer'))

interface MainProps {
    noMargin?: boolean
}

const Main = styled.main<MainProps>`
    min-height: calc(100vh - var(--navbar-height));
    margin-top: ${({ noMargin }) => (noMargin ? '0' : `var(--navbar-height)`)};
    @media (max-width: 750px) {
        margin-top: ${({ noMargin }) => (noMargin ? '0' : `60px`)};
    }
`

export type CustomPageProps = {
    initialReduxState: State
    _nextI18Next: SSRConfig['_nextI18Next']
}

interface CustomAppProps extends AppProps {
    pageProps: CustomPageProps
    hasContentCampaignRunning: any
}

function MyApp({ Component, pageProps, hasContentCampaignRunning }: CustomAppProps) {
    const store = useStore(pageProps.initialReduxState)
    const router = useRouter()
    const parsedLocale = parseLocale(router.locale)
    const landingPagesSlugs = getLandingPageSlugs(parsedLocale.contentLanguageCode)
    const { enableMicrosoftClarifyPageWide } = getCountryConfig(parsedLocale.countryCode)
    const pagesWithoutNavbarAndFooter = getPagesWithoutNavbarAndFooter(parsedLocale.contentLanguageCode)
    const pagesWithoutFooter = getPagesWithoutFooter(parsedLocale.contentLanguageCode)

    const slug = router.asPath
        // eslint-disable-next-line no-useless-escape
        ?.split(/(\?|\#)/g)[0]
        ?.split('/')
        ?.pop()
        // eslint-disable-next-line no-useless-escape
        ?.split(/(\?|\#)/g)[0]

    const isHomepage = router.pathname === '/'
    const isLandingPage = isHomepage || (!!slug && landingPagesSlugs?.includes(slug))

    const hideNavbarAndFooter = pagesWithoutNavbarAndFooter?.includes(router.pathname)
    const hideFooter = pagesWithoutFooter?.includes(router.pathname)

    const hideNavbarMargin = !!isLandingPage || !!hideNavbarAndFooter

    useEffect(() => {
        // Get attribution on page load to fetch query parameters
        getAttribution()
        initiatePageTracking()
    }, [])

    const microsoftClarityEnabledRoutes = [advertiseRoute, calendlyRoute]
    const addClarity =
        enableMicrosoftClarifyPageWide ||
        microsoftClarityEnabledRoutes.includes(getPathWithoutQuery(router.asPath)) ||
        shouldEnableClarityForMinorityOfUsers()

    return (
        <Provider store={store}>
            <FacebookMessengerWidget />
            {addClarity ? <MicrosoftClarityTag /> : null}
            <AuthenticationHandler />
            <NoIndexAlternativeLocaleTag />

            <div hidden={hideNavbarAndFooter}>
                <Header
                    isLandingPage={isLandingPage}
                    isHomepage={isHomepage}
                    hasContentCampaignRunning={hasContentCampaignRunning}
                />
            </div>

            <Main noMargin={hideNavbarMargin}>
                <Component {...pageProps} />
            </Main>
            {!hideNavbarAndFooter && !hideFooter ? <Footer /> : null}

            <ToastContainer position="bottom-right" theme="light" />
            {!hideNavbarAndFooter ? <CookieBanner /> : null}
        </Provider>
    )
}

MyApp.getInitialProps = async (ctx: AppContext) => {
    const countryLanguageConfig = getCountryLanguageConfig(ctx.router.locale)
    const isExpansion = isExpansionContentCompetitionLive(countryLanguageConfig)

    return {
        hasContentCampaignRunning: !!isExpansion,
    }
}

export default appWithTranslation<CustomAppProps>(MyApp)
